<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('notelist[0]')"
            left-arrow
            @click-left="goLast"
    >
    </van-nav-bar>
    <div class="bgc">
    </div>
    <div class="bg">
      <div class="panel" v-if="pageState!=1">
          <div class="mytable"v-for="(item, index) in listData" :key="index">
            <van-cell class="FundItem" :title="item.note.substring(1,15)" :label="item.time" is-link :border="false" @click="goDetail(index)">

            </van-cell>
          </div>
      </div>
      <div class="itemDetail" v-else>
        <div style="width: 95%;white-space:normal; word-break:break-all">{{currItem.note}}</div>
        <div>{{currItem.time}}</div>
        <div v-for="(img,i) in JSON.parse(currItem['screenshots'])">
            <img :src="img" style="width: 100px"/>

        </div>
      </div>
    </div>

  </div>

</template>

<script>
  export default {
    name: 'Wallet',
    components: {

    },
    props: ['walletType','id'],
    data() {
      return {
        pageState:0,
        currIndex:-1,
        currItem:{},
        active:0,
        isLoad: false,//显示正在加载
        isFinished: false,//控制是否禁止触发上拉触底的事件
        pageNo:1,
        pagesize:200,
        listData:[],
        robotUserName:localStorage['robotUserName']||''
      }
    },
    created() {
      this.active = 0;
      this.listData = [];
      this.getListData("init");
    },
    mounted() {

    },
    methods: {
      goLast(){
        if (this.pageState==1){
          this.pageState = 0;
          return;
        }
        this.$router.go(-1)
      },
      //触底触发，页面初始化触发
      onLoad() {
        // 异步更新数据
        // this.getListData("load");
      },
      goDetail(index){
        this.pageState = 1;
        this.currIndex=index;
        this.currItem = this.listData[index];
      },
      //获取用户历史记录
      getListData(type) {
        this.isLoad = true;
        let json={
          pagesize:this.pagesize
        }
        this.pages++
        this.$Model.GetNote(json,
                (data) => {
                  this.isLoad = false;
                  if (data.code == 1&&data.data) {
                    this.listData = data.data;
                  } else {
                    this.listData = [];
                    this.isFinished = true;
                  }
                })
      },
    },
  }
</script>

<style scoped>
  /* .van-nav-bar--fixed,.Site .van-nav-bar{
  background-color: #0f25fe;
} */
  .PageBox {
    overflow: auto;
    color: #e7e7e7;
    padding-top: 40px;
    background-color: #0F4F4E;
  }
  .PageBox>>>.van-nav-bar{
    background-color: #0F4F4E;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#FFFEFF;
    font-weight: bold;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#FFFEFF;
  }
  .PageBox>>>.van-cell div {
    color: #FFFEFF;
  }
  .PageBox .bgc {
    width: 100%;
    background-color: #0F4F4E;
    height: 46px;
    position: fixed;
    top: 0;
    z-index: 200;
  }

  .PageBox .bg{
    background-color: #0F4F4E;
  }

  .PageBox .panel{
    width: 95%;
    margin-left: 10px;
    margin-top: 10px;
  }

  .FundItem {
    margin-top: 10px;
    background-color: #337F7B;
    border-radius: 10px;
    line-height: 30px;
    color: #e7e7e7
  }
  .van-cell>>>.van-icon{
    font-size: 20px;
    color: #89BFC1;
  }
  .topTitle {
    position: absolute;
    max-width: 70%;
    white-space: nowrap;
    font-size: 18px;
    color: #fff;
    background-color: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .itemDetail{
    margin: 10px auto;
    border: 1px solid;
    width:90%;
    background-color: #337F7B;
    border-radius: 5px;
    line-height: 30px;
    padding: 10px;
  }
  .PageBox>>>.mytable {
    margin: 0 auto;
    background-color: #0F4F4E;
  }

  .PageBox>>>table {
    width: 100%;
    border: none;
    /* border-spacing: 1px; */
  }

  .PageBox>>>th:nth-child(1) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 26%;
  }

  .PageBox>>>th:nth-child(2) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 24px;
  }

  .PageBox>>>th:nth-child(3) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 24%;
  }

  .PageBox>>>th:nth-child(4) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 26%;
  }

  .PageBox>>>table tr td {
    font-size: 11px;
    color: #626262;
    padding: 8px 0px;
    text-align: center;
    border: none;
  }

  table tbody tr:nth-child(odd) {
    background: #eff0f2;
  }

  table tbody tr:nth-child(even) {
    background: #e1e1f9;
  }
</style>
